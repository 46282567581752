function openReview(){
    $('.popup_review').show(0);
    $('.popup_review').addClass('active');
}

function clampText(selector, line) {
    var targets = document.querySelectorAll(selector);
    targets.forEach(function (el) {
        $clamp(el, {
            clamp: line
        });
    });
}


$(document).ready(function () {
    svg4everybody();

    $('.gallery__item').fancybox();

    $('.certificate').fancybox();
    
    $('input[type="number"], select').styler();

    clampText('.news-preview__text', 2);
    
    
    if (screen.width <= 1440) {
        clampText('.services__text', 4);
    }
    
    if (screen.width < 1025) {
    
        $("#menu").mmenu({
            "extensions": [
                "pagedim-black",
                "position-right",
                "theme-dark"
            ],
            "navbar": {
                title: "Меню"
            },
        }, {
            offCanvas:{
                page: {
                    nodetype: "main"
                }
            }
        }); 
        
        $('#menu').data('mmenu').bind( "close:start", function() {
            $('.navigation__hamburger').removeClass( "navigation__hamburger_active" );
        });
        
    }
    else {
        new WOW().init();
    }
    
    $('.navigation__hamburger').click(function(){
        $(this).toggleClass('navigation__hamburger_active');
    });
    
    $('.show_review').on('click', function() {
        var _this = $(this);
        var reviewText = _this.prev('.review_text');
        reviewText.find('.review_text_hidden').fadeIn().css('display', 'inline');
        reviewText.removeClass('cropped');
        _this.hide(0);
    });
    
    $('.add_review').click(openReview);
   
    $('.slider.owl-carousel').owlCarousel({
        loop:true,
        nav:false,
        dots:false,
        items:1,
        autoplay: true,
        autoplayTimeout:20000,
        autoplayHoverPause:true,
    });
    
    $('.gallery__wrapper.owl-carousel').owlCarousel({
        autoplay:true,
        autoplayTimeout:10000,
        autoplayHoverPause:true,
        loop: true,
        nav: false,
        dots:true,
        responsive:{
            0:{
                items:1,
                margin:0
            },
            600:{
                items:3,
                margin:20
            },
            1024:{
                items:4,
                margin:20
            }
        }
    });
    
    $('.main_gallery .gallery_item a').fancybox();
    
    $('.popup_close, .popup_close_x').click(closeFeedback);
    
    $('.open_popup').click(openFeedback);

    $('input[type="tel"]').mask('+7 (999) 999-99-99');
    
    $('a[href="#popup"]').click(function(){
        openFeedback();
        return false;
    });
    
    $('.product__price-info button').click(function(){
        $('.popup_product_bg').show(0);
        $('.popup_product_bg').addClass('active');
    });
    
    function openFeedback(){
        $('.popup_bg').show(0);
        $('.popup_bg').addClass('active');
    }
    
    function closeFeedback(){
        var popup = $(this);
        if(popup.hasClass('popup_close_x')){
            popup = popup.parent('.popup');
        }
        popup.parent('.main_popup').removeClass('active');
        setTimeout(function(){
            popup.parent('.main_popup').hide(0)
        }, 500);
    }
    
    $('.js_popup_order').click(function(){
        $('.popup_product_bg').show(0);
        $('.popup_product_bg').addClass('active');
        var service_title = $(this).attr('data-title');
        var service_price = $(this).attr('data-price');
        $('.popup_product_bg .form_service_title').text(service_title);
        $('.popup_product_bg .form_service_price').text(service_price);
        $('.popup_product_bg .hidden_service_title').attr('value', service_title);
        $('.popup_product_bg .hidden_service_price').attr('value', service_price);
        
    });
    
    $('form').on('submit', function(e) {
        e.preventDefault();
        var thisForm = $(this);
        $.ajax(thisForm.attr('action'), {
            data: thisForm.serializeArray(),
            type: thisForm.attr('method'),
        }).done(function(errMessage) {
            errMessage = errMessage.trim();
            if(errMessage == ""){
                thisForm.trigger('reset');
                $('.main_popup').not('.thanks_bg').removeClass('active');
                setTimeout(function(){
                    $('.main_popup').not('.thanks_bg').hide(0)
                }, 500);
                $('.thanks_bg').show(0);
                $('.thanks_bg').addClass('active');
            }
            else {
                alert('Подтвердите, что Вы не робот!');
            }
        });
    });
    
    $('.thanks_close_button').click(function(){
        $('.thanks_bg').removeClass('active');
        setTimeout(function(){$('.thanks_bg').hide(0)}, 500);
    });
    
    
    $('.price__header').click(function(){
        var _this = $(this);
        if(_this.hasClass('price__header_active')){
            _this.next('.price__table').hide(300);
            _this.removeClass('price__header_active');
        } else {
            $('.price__header_active').next('.price__table').hide(300);
            $('.price__header_active').removeClass('price__header_active');
            _this.addClass('price__header_active');
            _this.next('.price__table').show(300);
        }
        
        setTimeout(function() {
            var destination = _this.offset().top;
            $('html, body').animate({ scrollTop: destination }, 300);
        }, 300);
    });
    
    $('.get_to_us span').click(function(){
        var class_name = '.' + $(this).attr('class');
        if($(this).hasClass('active')){
            class_name = class_name.replace(' active','');
            $(class_name + '_block').removeClass('active');
            $(this).removeClass('active');
        } else {
            $('.get_to_us .get_to_block').removeClass('active');
            $('.get_to_us span.active').removeClass('active');
            $(this).addClass('active');
            $(class_name + '_block').addClass('active');
        }
    });
    
    if (screen.width < 601) {
        $('.advantages__wrapper').addClass('owl-carousel');
        $('.services__wrapper').not('.page .services__wrapper').addClass('owl-carousel');
        $('.catalog__wrapper').not('.page .catalog__wrapper').addClass('owl-carousel');
        $('.mobile_news').addClass('owl-carousel');
        
        $('.advantages__wrapper.owl-carousel').owlCarousel({
            loop:true,
            nav:true,
            dots:false,
            items:2,
            navText:['',''],
            autoplay:true,
            autoplayTimeout:10000,
            autoplayHoverPause:true,
        });
        
        $('.services__wrapper.owl-carousel').owlCarousel({
            loop:true,
            nav:false,
            dots:true,
            items:1,
            autoplay:true,
            autoplayTimeout:10000,
            autoplayHoverPause:true,
        });

        $('.catalog__wrapper.owl-carousel').owlCarousel({
            loop:true,
            nav:false,
            dots:true,
            items:2,
            margin:20,
            autoHeight: true,
            autoplay:true,
            autoplayTimeout:10000,
            autoplayHoverPause:true
        });
        
        $('.mobile_news.owl-carousel').owlCarousel({
            loop:true,
            nav:false,
            dots:true,
            items:1,
            autoplay:true,
            autoplayTimeout:10000,
            autoplayHoverPause:true,
        });

        // var catUnit = document.querySelectorAll('.catalog__unit');
        // var maxHeight = 0;
        // catUnit.forEach(function (el) {
        //     if (el.offsetHeight > maxHeight) maxHeight = el.offsetHeight;
        // });
        // $('.catalog__unit').css('height', maxHeight);
    }
    
    function heightElement(class_element){
    	var max_p_height = 0; // максимальная высота, первоначально 0
    	$(class_element).each(function(){ // цикл "для каждой из колонок"
    		if ($(this).height() > max_p_height) { // если высота колонки больше значения максимальной высоты,
    			max_p_height = $(this).height(); // то она сама становится новой максимальной высотой
    		}
    	});
    	$(class_element).height(max_p_height); // устанавливаем высоту каждой колонки равной значению максимальной высоты
    }
    
    heightElement('.service_price_item .service_title');
    
    heightElement('.service_icon_title');
    

    $('.js-anchor').on('click', function (e) {
        e.preventDefault();
        var href = $(this).attr('href');
        var price = document.querySelector('#js-price');
        if (price !== null) {
            price.scrollIntoView({
                behavior: 'smooth' 
            });
        } else window.location = href;
    });
});